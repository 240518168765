const mapStyles = [
	{
		elementType: 'geometry',
		stylers: [
			{
				color: '#f5f5f5',
			},
			{
				weight: 0.5,
			},
		],
	},
	{
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#616161',
			},
		],
	},
	{
		elementType: 'labels.text.stroke',
		stylers: [
			{
				color: '#f5f5f5',
			},
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'administrative.land_parcel',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#bdbdbd',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'geometry',
		stylers: [
			{
				color: '#eeeeee',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#757575',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry',
		stylers: [
			{
				color: '#e5e5e5',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#9e9e9e',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'geometry',
		stylers: [
			{
				color: '#ffffff',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'geometry',
		stylers: [
			{
				weight: 1,
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#757575',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry',
		stylers: [
			{
				color: '#dadada',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#616161',
			},
		],
	},
	{
		featureType: 'road.local',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#9e9e9e',
			},
		],
	},
	{
		featureType: 'transit.line',
		elementType: 'geometry',
		stylers: [
			{
				color: '#e5e5e5',
			},
		],
	},
	{
		featureType: 'transit.station',
		elementType: 'geometry',
		stylers: [
			{
				color: '#eeeeee',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry',
		stylers: [
			{
				color: '#c9c9c9',
			},
			{
				weight: 0.5,
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#9e9e9e',
			},
		],
	},
];

const leeds = {
	position: { lat: 53.80633, lng: -1.44766 },
	content:
		'<div class="name">Approved Leeds - Head Office</div>0113 202 6059',
};
const glasgow = {
	position: { lat: 55.862478, lng: -4.255494 },
	content: '<div class="name">Approved Glasgow</div>0141 4040569',
};
const trowbridge = {
	position: { lat: 51.32953, lng: -2.18088 },
	content: '<div class="name">Approved Trowbridge</div>01225 697080',
};
const bristol = {
	position: { lat: 51.54531, lng: -2.57157 },
	content: '<div class="name">Approved Bristol</div>0117 374 3076',
};
const center = { lat: 53.818693, lng: -4.892917 };

module.exports = {
	mapStyles,
	leeds,
	glasgow,
	trowbridge,
	bristol,
	center,
	points: [leeds, glasgow, trowbridge, bristol],
};
