require('./frontend.scss');
require('./frontend-resources.js');

const $ = (window.jQuery = require('jquery'));
require('jquery-lazy');
require('slick-carousel');

const mapData = require('./map-data');

function ajaxForm(sel) {
	$(sel).submit(function (e) {
		const me = this;
		const $me = $(me);
		const url = $me.attr('action');
		const action = me.dataset.action;
		e.preventDefault();

		const data = new FormData(me);
		grecaptcha.ready(function () {
			grecaptcha
				.execute('6LdDX_wUAAAAAMbJlRakXvcFCmCVYA28R4I-nuT2', { action })
				.then(function (token) {
					data.set('recaptcha', token);

					$me.find('input, select, textarea').removeClass('error');
					$.ajax({
						url,
						type: 'post',
						data,
						processData: false,
						contentType: false,
						success: function (resp) {
							if (resp.success) {
								if (window.gtag) {
									window.gtag('event', 'submit', {
										event_category: 'Form',
										event_label: url,
									});
								}
								me.reset();
								$me.find('.alert').html(resp.message);
								return;
							}

							for (var i = resp.errors.length - 1; i >= 0; i--) {
								$me.find(
									'[name=' + resp.errors[i] + ']'
								).addClass('error');
							}
						},
					});
				});
		});
	});
}

function fancyLabels(sel) {
	$(sel).focus(function () {
		$(this).parent().addClass('move-label');
	});

	$(sel).blur(function () {
		if (!$(this).val()) {
			$(this).parent().removeClass('move-label');
		}
	});
}

function updateSliderPage(_e, slick, _currentSlide, nextSlide) {
	var slide =
		typeof nextSlide === 'undefined' ? slick.currentSlide : nextSlide;
	var total = slick.$slides.length;
	if (total <= 1) {
		return;
	}
	slick.$currentPage.html(slide + 1 + '<span></span>' + total);
}

function serviceMenuToggle(sel) {
	$(sel).click(function () {
		$(sel).toggleClass('active');

		if ($(window).outerWidth() > 1100) {
			$('.services-full-menu').toggleClass('active');
			$('nav').toggleClass('menu-active');
			if ($('.services-full-menu').hasClass('active')) {
				$(sel).find('p').html('Close');
			} else {
				$(sel).find('p').html('Services');
			}
		}
	});
}

// TODO
// if el is on page.
// 	do scroll.
// else
// 	go to
function smoothScroll(sel) {
	$(sel).click(function (e) {
		var target = $(this.hash);
		if (target.length) {
			e.preventDefault();
			$('html, body').animate({ scrollTop: target.offset().top }, 500);
		}
	});
}

function gaTriggers() {
	gtag('event', 'click', {
		event_category: $(this).data('ga-category'),
		event_label: $(this).data('ga-label'),
	});
}

function initMap() {
	const styles = mapData.mapStyles;
	const center = mapData.center;
	const points = mapData.points;

	const map = new google.maps.Map(document.getElementById('map'), {
		zoom: 6.25,
		center,
		disableDefaultUI: true,
		styles,
	});

	const Popup = createPopupClass();
	points.forEach((point) => {
		const { position, content } = point;

		const marker = new google.maps.Marker({ position, map });
		const popup = new Popup(position, renderPopup(content));

		marker.addListener('mouseover', () => popup.setMap(map));
		marker.addListener('mouseout', () => popup.setMap(null));
	});
}

function renderPopup(content) {
	var el = document.createElement('div');
	el.innerHTML = content;

	return el;
}

// see https://developers.google.com/maps/documentation/javascript/examples/overlay-popup
function createPopupClass() {
	/**
	 * A customized popup on the map.
	 */
	class Popup extends google.maps.OverlayView {
		/**
		 * @param {google.maps.ReadonlyLatLngLiteral} position position
		 * @param {HTMLElement} content bubble div
		 * @constructor
		 * @extends {google.maps.OverlayView}
		 */
		constructor(position, content) {
			super();
			this.position = new google.maps.LatLng(position.lat, position.lng);

			content.classList.add('popup-bubble');

			// This zero-height div is positioned at the bottom of the bubble.
			var bubbleAnchor = document.createElement('div');
			bubbleAnchor.classList.add('popup-bubble-anchor');
			bubbleAnchor.appendChild(content);

			// This zero-height div is positioned at the bottom of the tip.
			this.containerDiv = document.createElement('div');
			this.containerDiv.classList.add('popup-container');
			this.containerDiv.appendChild(bubbleAnchor);

			// Optionally stop clicks, etc., from bubbling up to the map.
			google.maps.OverlayView.preventMapHitsAndGesturesFrom(
				this.containerDiv
			);
		}

		onAdd() {
			this.getPanes().floatPane.appendChild(this.containerDiv);
		}

		/* Called when the popup is removed from the map. */
		onRemove() {
			if (this.containerDiv.parentElement) {
				this.containerDiv.parentElement.removeChild(this.containerDiv);
			}
		}

		/* Called each frame when the popup needs to draw itself. */
		draw() {
			var divPosition = this.getProjection().fromLatLngToDivPixel(
				this.position
			);

			// Hide the popup when it is far out of view.
			var display =
				Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
					? 'block'
					: 'none';

			if (display === 'block') {
				this.containerDiv.style.left = divPosition.x + 'px';
				this.containerDiv.style.top = divPosition.y + 'px';
			}
			if (this.containerDiv.style.display !== display) {
				this.containerDiv.style.display = display;
			}
		}
	}

	return Popup;
}

$(function () {
	ajaxForm('form.ajax');
	fancyLabels('.form .field input, .form select, .form textarea');
	serviceMenuToggle('.js-service-menu');
	smoothScroll('.js-smooth');
	$('.js-track').click(gaTriggers);

	var noHashURL = window.location.href.replace(/#.*$/, '');
	window.history.replaceState('', document.title, noHashURL);

	$('.js-toggle-read').click(function (e) {
		e.preventDefault();
		$(this).toggleClass('active');
		$(this).parent().find('.long-description').toggleClass('expanded');
		if ($(this).hasClass('active')) {
			$(this).text('Read Less');
		} else {
			$(this).text('Read More');
		}
	});

	$('.burger-menu').click(function () {
		$(this).toggleClass('active');
		$('nav .links').toggleClass('active');
	});

	$('.js-upload-toggle').click(function () {
		$('.cv-popout').fadeToggle();
		$('body').toggleClass('overflow');
	});

	$('.js-lazy').Lazy({
		effect: 'fadeIn',
		effectTime: 1000,
	});

	$('.js-scroll').click(function (e) {
		var target = $(this.hash);
		if (target.length) {
			e.preventDefault();
			$('html, body').animate({ scrollTop: target.offset().top }, 1000);
		}
	});

	const dotTitles = $('.banner .slide').map((_i, el) => el.dataset.title);
	const dots = function (_slick, i) {
		return dotTitles[i];
	};

	$('.homepage .banner')
		.slick({
			dots: true,
			arrows: false,
			speed: 1000,
			autoplay: true,
			autoplaySpeed: 6000,
			infinite: false,
			customPaging: dots,
		})
		.each(function () {
			$(this)
				.append(
					(this.slick.$currentPage = $(
						'<div class="current-page"></div>'
					))
				)
				.on('beforeChange', updateSliderPage);
			updateSliderPage(null, this.slick);
		});

	$('.testimonials .right')
		.slick({
			autoplay: true,
			speed: 1000,
			arrows: true,
			pauseOnHover: false,
			infinite: true,
			dots: false,
			autoplaySpeed: 14000,
			adaptiveHeight: true,
		})
		.each(function () {
			$(this)
				.append(
					(this.slick.$currentPage = $(
						'<div class="current-page"></div>'
					))
				)
				.on('beforeChange', updateSliderPage);
			updateSliderPage(null, this.slick);
		});

	$('.intro .slider')
		.slick({
			autoplay: true,
			speed: 1000,
			arrows: true,
			pauseOnHover: false,
			dots: false,
		})
		.each(function () {
			$(this)
				.append(
					(this.slick.$currentPage = $(
						'<div class="current-page"></div>'
					))
				)
				.on('beforeChange', updateSliderPage);
			updateSliderPage(null, this.slick);
		});

	/*
	This might look a bit strange. We have no way of knowing whether Google's
	map js will load before our own. As long as the following function is
	defined on the contact page:

	function initMap() { window.initMapCalled = true; }

	... then no matter which js loads first, the initMap() inside this file
	will be called at some point.

	There are probably some annoying race conditions with this, but it should
	work for now.
	 */
	window.initMap = initMap;
	if (window.initMapCalled) initMap();
});

// .load() deprec in JQ 3.0
$(window).on('load', function () {
	var page = window.location.pathname;
	if (page == '/') {
		$('.loader').delay(2000).fadeOut(2000);
	}
});

function fadeInItems(el) {
	var topElement = $(el).offset().top - 100; // How far of the page there is until the top of said element is there
	var botElement = $(el).offset().top + $(el).outerHeight(); // Above + total element size
	var botScreen = $(window).scrollTop() + $(window).innerHeight(); // Viewport size plus whatever you've scrolled (generally in 100 increments if you're mouse scrolling)
	var topScreen = $(window).scrollTop(); // How many pixels to scroll to the very top nav.

	if (botScreen > topElement && topScreen < botElement) {
		$(el).removeClass('faded');
	}
}

$(window).scroll(function () {
	$('.fade-in.faded').each(function () {
		fadeInItems(this);
	});
});
