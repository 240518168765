require('./image/favicon/android-chrome-192x192.png');
require('./image/favicon/android-chrome-512x512.png');
require('./image/favicon/apple-touch-icon.png');
require('./image/favicon/favicon-32x32.png');
require('./image/favicon/favicon-16x16.png');
require('./image/favicon/mstile-150x150.png');
require('./image/favicon/site.webmanifest');
require('./image/favicon/safari-pinned-tab.svg');

require('./image/approved-workforce-logo.svg');
require('./image/approved-workforce-white-logo.svg');
require('./image/alliance-group-logo.svg');
require('./image/lester-goddard.png');
require('./image/alliance-workforce.png');
require('./image/recruit-engineering-logo.svg');

require('./image/our-guarantee.jpg');

require('./image/homepage-intro-1.jpg');

require('./image/projects-banner.jpg');
require('./image/jobs-banner.jpg');
require('./image/contact-banner.jpg');

require('./image/contact-image.jpg');

require('./image/about-slider.jpg');

require('./image/residential-logo.svg');
require('./image/residential-slider.jpg');
require('./image/residential-intro-1.jpg');
require('./image/residential-purpose.jpg');
require('./image/residential-service.jpg');
require('./image/residential-guarantee.jpg');

require('./image/mechanical-logo.svg');
require('./image/mechanical-slider.jpg');
require('./image/mechanical-intro-1.jpg');
require('./image/mechanical-purpose.jpg');
require('./image/mechanical-service.jpg');
require('./image/mechanical-guarantee.jpg');

require('./image/construction-logo.svg');
require('./image/construction-slider.jpg');
require('./image/construction-intro-1.jpg');
require('./image/construction-purpose.jpg');
require('./image/construction-service.jpg');
require('./image/construction-guarantee.jpg');

require('./image/joinery-logo.svg');
require('./image/joinery-slider.jpg');
require('./image/joinery-intro-1.jpg');
require('./image/joinery-purpose.jpg');
require('./image/joinery-service.jpg');
require('./image/joinery-guarantee.jpg');

require('./image/about-banner.jpg');
require('./image/about-guarantee.jpg');
require('./image/about-intro-1.jpg');
require('./image/about-purpose.jpg');

require('./image/icons/8-hour.svg');
require('./image/icons/8.svg');
require('./image/icons/8-circle.svg');

require('./image/chas-logo.png');
require('./image/constructionline-logo.png');
require('./image/national-association-logo.png');
require('./image/safecontractor-logo.png');
require('./image/smas-logo.png');

require('./image/icons/enjoyment.svg');
require('./image/icons/exceed-expectations.svg');
require('./image/icons/honesty.svg');
require('./image/icons/over-achieve.svg');
require('./image/icons/ownership.svg');
require('./image/icons/remain-focused.svg');

require('./image/projects/alliance-leicester.png');
require('./image/projects/london-south-bank.png');
require('./image/projects/lush.png');
require('./image/projects/m-and-s.png');
require('./image/projects/primark.png');
require('./image/projects/sainsburys.png');
require('./image/projects/topshop.png');
require('./image/projects/university-of-exeter.png');

require('./image/projects/allenbuild.jpg');
require('./image/projects/ashfordhomes.jpg');
require('./image/projects/hills.jpg');
require('./image/projects/jehu.jpg');
require('./image/projects/persimmon.jpg');
require('./image/projects/taylor-wimpey.jpg');
require('./image/projects/wainhomes.jpg');

require('./image/projects/rj-stearn.jpg');
require('./image/projects/euro-properties.jpg');
require('./image/projects/tapper.jpg');
require('./image/projects/pacy-wheatley.jpg');
require('./image/projects/dunnington.jpg');
require('./image/projects/pec.jpg');
require('./image/projects/cerison.jpg');
require('./image/projects/caldercad.jpg');
require('./image/projects/jkc.jpg');
